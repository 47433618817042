import { gql, useMutation, useQuery } from '@apollo/client';

const Q_FAVORITE_SPOTS = gql`
    query favorites($filters: FiltersInputObject) {
        favoriteSpots(filters: $filters) {
            id
        }
    }
`;
const M_ADD_SPOT_TO_FAVORITES = gql`
    mutation toggleFavorite($id: ID!) {
        addSpotToFavorites(id: $id) {
            id
        }
    }
`;
const M_RM_SPOT_FROM_FAVORITES = gql`
    mutation toggleFavorite($id: ID!) {
        removeSpotFromFavorites(id: $id) {
            id
        }
    }
`;

export const useGetFavorites = () => {
    const { data, loading } = useQuery(Q_FAVORITE_SPOTS);
    return { favSpots: data?.favoriteSpots, loading };
};

export const useMutateFavorites = (isFavorite) => {
    const [toggleFavSpot, { data, loading }] = useMutation(
        isFavorite ? M_RM_SPOT_FROM_FAVORITES : M_ADD_SPOT_TO_FAVORITES
    );
    return { toggleFavSpot, data, loading };
};
