import React from 'react';
import bemCn from 'bem-cn';
import CookieService from '../../services/cookie.service';
import { NAV_ITEMS } from '../navigation/navigation.consts';
import { RouteFormatter } from '../../routes';
import RenderMobileDefaultRightSSR from './default-right-ssr';
import NavigationGuestUnauth from '../navigation-guest-unauth';
import NavigationLayoutSSR from '../navigation-layout/layout-ssr';
import NavigationLogoSSR from '../navigation-logo/nav-logo-ssr';
import HeaderLogoWrapper from '../header-logo-wrapper';
import SmartBanner from '../smart-banner';
import './style.scss';

const b = bemCn('navigation-guest');

const NavigationSSR = ({
    userDetails,
    isMobileSSR: isMobile,
    useState,
    useEffect,
    useMemo,
    pathname,
    isSpotPage,
    spot,
    useGetShareLink,
    useCallback,
    useMutateFavorites,
    useGetFavorites,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const isAuthenticated = !!userDetails?.id;
    const hasSpots = isAuthenticated && userDetails.spots?.length > 0;
    const hasUnconsumedMessage = [];
    const isBlog = pathname == RouteFormatter.blog();
    const isListing =
        pathname == RouteFormatter.listings({}) || pathname == RouteFormatter.listings2({}) || pathname == RouteFormatter.listingsVideo({});
    const isBlogSub = pathname.startsWith('/blog/');

    const toggleMenu = (e) => {
        e?.nativeEvent?.stopImmediatePropagation();
        setIsOpen(!isOpen);
    };

    const navigationConfig = useMemo(
        () =>
            [
                !isAuthenticated && NAV_ITEMS.LOGIN,
                isAuthenticated && NAV_ITEMS.VISIT_LIST,
                NAV_ITEMS.SUBSCRIPTIONS,
                isAuthenticated && { ...NAV_ITEMS.GUEST_MESSAGES, notification: hasUnconsumedMessage.length > 0 },
                isAuthenticated && NAV_ITEMS.ACCOUNT,
                NAV_ITEMS.EXPLORE_SPOTS,
                isAuthenticated && NAV_ITEMS.INVITE_FRIENDS,
                isAuthenticated && NAV_ITEMS.FAVORITES,
                hasSpots ? NAV_ITEMS.SWITCH_TO_HOST : NAV_ITEMS.HOST_WITH_SNIFF,
                NAV_ITEMS.SEPARATOR,
                NAV_ITEMS.TRUST_SAFETY,
                NAV_ITEMS.HELP_CENTER,
                NAV_ITEMS.BLOG,
                isAuthenticated && NAV_ITEMS.SEPARATOR,
                isAuthenticated && NAV_ITEMS.SIGN_OUT,
            ].filter((item) => !!item),
        [isAuthenticated, userDetails, hasUnconsumedMessage]
    );

    useEffect(() => {
        if (userDetails?.id && userDetails?.latitude && userDetails?.longitude) {
            const dataUser = {
                location: { lat: userDetails.latitude, lng: userDetails.longitude },
                userLocationConfirm: true,
            };
            CookieService.set('user', JSON.stringify(dataUser));
        }

        // navigator.geolocation.getCurrentPosition(
        //     (position) => {
        //         const coords = position.coords;
        //         const dataUser = {
        //             location: { lat: coords.latitude, lng: coords.longitude },
        //             userLocationConfirm: true,
        //         };
        //         CookieService.set('user', JSON.stringify(dataUser));
        //     },
        //     (err) => console.warn(err),
        //     { enableHighAccuracy: true, maximumAge: 60 * 1000, timeout: 2000 }
        // );
    }, [userDetails, isAuthenticated]);

    useEffect(() => {
        if (isMobile) {
            const htmlEle = document.documentElement;
            if (isOpen) {
                htmlEle.classList.add('root-html-focused');
            } else {
                htmlEle.classList.remove('root-html-focused');
            }
            return () => htmlEle.classList.remove('root-html-focused');
        }
    }, [isMobile, isOpen]);

    if (isMobile === null) {
        return '';
    }

    // WHEN IN MOBILE
    return (
        <>
            <SmartBanner />
            <NavigationGuestUnauth className="d-none d-md-flex" />
            <NavigationLayoutSSR className="mob-show d-flex d-md-none" style={isListing ? { height: '72px' } : {}}>
                <div className={b('left')}>
                    {!isListing && <NavigationLogoSSR {...{ isMobile, useState, isSpotPage, pathname, spot }} full />}
                    {/* WHEN IN LISTING */}
                    {isListing && (
                        <HeaderLogoWrapper
                            toggleMenu={toggleMenu}
                            isMenuOpened={isOpen}
                            isAuthenticated={false}
                            className="listing-unauth-menu"
                            isMobileSSR={isMobile}
                            userDetails={userDetails}
                            useState={useState}
                            useEffect={useEffect}
                            isListing={isListing}
                            SSR
                        />
                    )}
                </div>
                {!isListing && (
                    <RenderMobileDefaultRightSSR
                        {...{
                            navigationConfig,
                            toggleMenu,
                            isMenuOpened: isOpen,
                            isAuthenticated,
                            isMobile,
                            useState,
                            userDetails,
                            useEffect,
                            isSpotPage,
                            spot,
                            useGetShareLink,
                            useCallback,
                            useMutateFavorites,
                            useGetFavorites,
                            isBlog,
                            isBlogSub,
                        }}
                    />
                )}
            </NavigationLayoutSSR>
        </>
    );
};

export default NavigationSSR;
