import React from 'react';
import bemCn from 'bem-cn';
import loadable from '@loadable/component';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import NavGuestMobileUnauthSSR from '../navigation-guest-unauth/mobile-ssr';
import SvgBarsSolid from 'src/assets/svg/SvgBarsSolid.svg';
import SvgChevronDown from 'src/assets/svg/SvgChevronDown.svg';
import SvgChevronUp from 'src/assets/svg/SvgChevronUp.svg';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import './style.scss';

const UserLogo = loadable(() => import('../user-logo'));
const UserLogoSSR = loadable(() => import('../user-logo/user-logo-ssr'));
const NavigationMenu = loadable(() => import('../navigation-menu'));
const NotificationIcon = loadable(() => import('../notification-icon'));
const NavigationMenuSSR = loadable(() => import('../navigation-menu/nav-menu-ssr'));
const NavigationHostMenu = loadable(() => import('../navigation-host-menu'));
const NavGuestMobileUnauth = loadable(() => import('../navigation-guest-unauth/mobile'));
const NavigationHostMenuSSR = loadable(() => import('../navigation-host-menu/host-menu-ssr'));

const b = bemCn('header-logo-wrapper');

const HeaderLogoWrapper = ({
    toggleMenu,
    isMenuOpened,
    config,
    className,
    isFixed,
    menuIcon,
    isAuthenticated,
    userDetails = {},
    useEffect,
    useState,
    isListing,
    SSR,
}) => {
    const isMobile = useMobileScreen();
    const seoMenu = isMobile && !isAuthenticated;

    return (
        <>
            <div className={b.mix(className)} onClick={toggleMenu}>
                {menuIcon || seoMenu ? (
                    isMenuOpened ? (
                        <SvgCross2 width={24} height={24} />
                    ) : (
                        <SvgBarsSolid width={24} height={24} />
                    )
                ) : (
                    <>
                        <div className={b('toggler')}>
                            {SSR ? (
                                <UserLogoSSR className={b('user-logo')} {...{ userDetails, useState }} method="eager" />
                            ) : (
                                <UserLogo className={b('user-logo')} method="eager" />
                            )}
                            <NotificationIcon className={b('notification-icon')} config={config} />
                        </div>
                        {isMenuOpened ? <SvgChevronUp className={b('menu-icon')} /> : <SvgChevronDown className={b('menu-icon')} />}
                    </>
                )}
            </div>
            {SSR &&
                (isMobile ? (
                    isAuthenticated ? (
                        <NavigationHostMenuSSR items={config} active={isMenuOpened} handleLeave={toggleMenu} userDetails={userDetails} />
                    ) : (
                        <NavGuestMobileUnauthSSR active={isMenuOpened} handleLeave={toggleMenu} useState={useState} isListing={isListing} />
                    )
                ) : (
                    <NavigationMenuSSR
                        items={config}
                        active={isMenuOpened}
                        isFixed={isFixed}
                        isMobile={isMobile}
                        useEffect={useEffect}
                        handleLeave={toggleMenu}
                        spots={userDetails.spots}
                    />
                ))}
            {!SSR &&
                (isMobile ? (
                    isAuthenticated ? (
                        <NavigationHostMenu active={isMenuOpened} handleLeave={toggleMenu} items={config} />
                    ) : (
                        <NavGuestMobileUnauth active={isMenuOpened} handleLeave={toggleMenu} isListing={isListing} />
                    )
                ) : (
                    <NavigationMenu active={isMenuOpened} handleLeave={toggleMenu} items={config} isFixed={isFixed} />
                ))}
        </>
    );
};

export default HeaderLogoWrapper;
